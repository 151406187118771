<template>
  <div class="admin-edit-sim-card" v-if="!loading">
        <div class="row">
          <div class=" col s4">
            <label for="sim_id" class="device-label">Sim ID</label>
            <input type="text" v-model="simCardInternal.sim_id" class="text-field w-input"
                   maxlength="256" placeholder="SIM Id" id="sim_id" disabled>
          </div>
          <div class="col-4">
            <label for="sim_status" class="device-label">Status</label>
            <b-form-select type="text" v-model="simCardInternal.status" class="text-field w-input" :options="statusOptions"
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Status" id="sim_status"
                    @change="changeStatus"
            />
          </div>
          <div class="col-4">
            <label for="sim_iccid" class="device-label">ICCID</label>
            <input type="text" v-model="simCardInternal.iccid" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="ICCID" id="sim_iccid">
          </div>
          <div class="col-4">
            <label for="sim_imsi" class="device-label">IMSI</label>
            <input type="text" v-model="simCardInternal.imsi" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="IMSI" id="sim_imsi">
          </div>
          <div class="col-4">
            <label for="sim_msisdn" class="device-label">MSISDN</label>
            <input type="text" v-model="simCardInternal.msisdn" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="MSISDN" id="sim_msisdn">
          </div>
          <div class="col-4">
            <label for="device_imei" class="device-label">Device IMEI</label>
            <b-input-group>
              <b-input type="text" v-model="simCardInternal.device_imei" class="text-field w-input" disabled
                            maxlength="256" name="IMEI" data-name="Device IMEI" placeholder="Device ID" id="device_imei" />
              <b-input-group-append>
                <b-button>
                  <i class="fa fa-external-link row-icon-button" @click="clickDevice(simCardInternal.device_id)"
                     v-b-tooltip.hover title="View Device"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>

          </div>
          <div class="col-4">
            <label for="sim_provider" class="device-label">SIM Provider</label>
            <input type="text" v-model="simCardInternal.sim_provider_config_id" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Prodiver" id="sim_provider">
          </div>
          <div class="col-4">
            <label for="sim_provider_id" class="device-label">SIM Provider ID</label>
            <input type="text" v-model="simCardInternal.sim_provider_id" class="text-field w-input" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Provider ID" id="sim_provider_id">
          </div>
          <div class="spacing"></div>
        </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import * as AlertHelper from '../helpers/AlertHelper'

export default {
  name: 'admin-edit-sim-card',
  components: {
  },
  props: {
    isModal: Boolean,
    modal: String,
    simCard: Object
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      selectedDevices: null,
      simCardInternal: {
      },
      statusOptions: [
        { value: '0', text: 'Issued' },
        { value: '1', text: 'Active' },
        { value: '2', text: 'Inactive' },
        { value: '3', text: 'Error' }
      ]
    }
  },
  async created () {
    if (this.simCard) {
      console.log(this.simCard)
      this.simCardInternal = { ...this.simCard }
    }
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.loading = false
  },
  methods: {
    changeStatus: async function (newStatus) {
      console.log(newStatus)
      let res = await DataProvider.adminUpdateSIM(this.simCardInternal.sim_id, newStatus)
      if (res.success) {
        console.log(res)
        if (res.data.updates.status) {
          AlertHelper.successToast('SIM Status Updated', 'Changes Saved!')
        } else {
          ErrorHelper.displayGeneralWarningToast('SIM status update completed, but the server did not confirm ' +
            'the change! Probably a Comms error with the SIM provider' +
            '', 'SIM Status Update Incomplete')
        }
        this.$emit('save')
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .admin-edit-product {
    color: $text-color-invert;
  }

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
  }

  .icon-button, .icon-button-danger{
    font-size: 1.9rem;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .notification-container {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: auto;
  }

  .notification-item{
    background: $theme-color-background-1;
  }

</style>
