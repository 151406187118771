var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-sim-card-list" },
    [
      _c(
        "div",
        { staticClass: "notification-table" },
        [
          !_vm.hideFilter
            ? _c("div", { staticClass: "filter-bar" }, [
                _c(
                  "div",
                  [
                    _c(
                      "b-input-group",
                      { attrs: { size: "sm" } },
                      [
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.showAdvancedFilters = !_vm.showAdvancedFilters
                                  }
                                }
                              },
                              [_vm._v("Adv Filters")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAdvancedFilters,
                            expression: "showAdvancedFilters"
                          }
                        ]
                      },
                      [
                        _c("search-filters", {
                          ref: "searchFilters",
                          attrs: { filters: _vm.filters },
                          on: {
                            change: _vm.changeFilter,
                            "press-enter": _vm.applyFilter
                          }
                        }),
                        _c("b-button", { on: { click: _vm.resetFilters } }, [
                          _vm._v("Clear")
                        ]),
                        _vm.filterMethod === "remote"
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: { click: _vm.applyFilter }
                              },
                              [_vm._v("Apply")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("b-table", {
            ref: "dataTable",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.tableDataSource,
              fields: _vm.tableFields,
              "select-mode": _vm.selectMode,
              "selected-variant": "success",
              selectable: _vm.selectable,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage,
              "td-class": "admin-device-table-data",
              "th-class": "admin-device-table-header",
              "sort-desc": true,
              "sort-by": "id"
            },
            on: { "row-selected": _vm.selectRow },
            scopedSlots: _vm._u([
              {
                key: "head(selected)",
                fn: function(row) {
                  return undefined
                }
              },
              {
                key: "cell(selected)",
                fn: function(row) {
                  return undefined
                }
              },
              {
                key: "cell(action)",
                fn: function(row) {
                  return [
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "row-icon-button row-action ml-2",
                      class: _vm.$config.icons.general.edit,
                      attrs: { title: "Edit SIM" },
                      on: {
                        click: function($event) {
                          return _vm.editSIM(row.item)
                        }
                      }
                    }),
                    row.item.device_id === null
                      ? _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "row-icon-button row-action ml-2",
                          class: _vm.$config.icons.general.assign,
                          attrs: { title: "Assign SIM to Device" },
                          on: {
                            click: function($event) {
                              return _vm.assignSIM(row.item)
                            }
                          }
                        })
                      : _vm._e(),
                    row.item.device_id !== null
                      ? _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "row-icon-button row-action ml-2",
                          class: _vm.$config.icons.general.unassign,
                          attrs: { title: "Unassign SIM" },
                          on: {
                            click: function($event) {
                              return _vm.unAssignSIM(row.item)
                            }
                          }
                        })
                      : _vm._e(),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "row-icon-button-danger row-action ml-2",
                      class: _vm.$config.icons.general.remove,
                      attrs: { title: "Delete SIM" },
                      on: {
                        click: function($event) {
                          return _vm.deleteSim(row.item)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-bar" },
        [
          _c("b-form-select", {
            staticClass: "page-select",
            attrs: {
              id: "perPageSelect",
              size: "sm",
              options: _vm.pageOptions
            },
            model: {
              value: _vm.perPage,
              callback: function($$v) {
                _vm.perPage = $$v
              },
              expression: "perPage"
            }
          }),
          _c("b-pagination", {
            staticClass: "page-buttons",
            attrs: {
              "total-rows": _vm.totalRows,
              "per-page": _vm.perPage,
              align: "fill",
              size: "sm"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-edit-sim-card",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Edit SIM"
          }
        },
        [
          _c("admin-edit-sim-card", {
            attrs: {
              "sim-card": _vm.selectedSIM,
              modal: "modal-edit-sim-card"
            },
            on: { save: _vm.refreshTable }
          }),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-edit-sim-card")
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-assign-sim-card",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Assign SIM to Device"
          }
        },
        [
          _c("admin-device-list", {
            attrs: {
              "show-fields": ["device_imei", "name", "device_type"],
              "select-mode": "single"
            },
            on: { "row-selected": _vm.selectDevice }
          }),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-assign-sim-card")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  attrs: { disabled: !_vm.selectedDevice },
                  on: {
                    click: function($event) {
                      return _vm.assignToDevice()
                    }
                  }
                },
                [_vm._v("Assign Device")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }