<template>
  <div class="admin-sim-card-list">
    <div class="notification-table">
      <div class="filter-bar" v-if="!hideFilter">
        <div>
          <b-input-group size="sm">
            <b-input-group-append>
              <b-button @click="showAdvancedFilters = !showAdvancedFilters">Adv Filters</b-button>
            </b-input-group-append>
          </b-input-group>
          <div v-show="showAdvancedFilters" class="">
            <search-filters
              :filters="filters"
              @change="changeFilter"
              @press-enter="applyFilter"
              ref="searchFilters"
            ></search-filters>
            <b-button
               @click="resetFilters"
            >Clear</b-button>
            <b-button
              v-if="filterMethod === 'remote'"
              @click="applyFilter"
              variant="primary"
            >Apply</b-button
            >
          </div>
        </div>
      </div>
      <b-table
        striped
        hover
        :items="tableDataSource"
        :fields="tableFields"
        :select-mode="selectMode"
        selected-variant="success"
        :selectable="selectable"
        @row-selected="selectRow"
        :current-page="currentPage"
        :per-page="perPage"
        td-class="admin-device-table-data"
        th-class="admin-device-table-header"
        ref="dataTable"
        :sort-desc="true"
        sort-by="id"
      >
        <!--Read/Unread Header Element-->
        <template v-slot:head(selected)="row">
        </template>
        <template v-slot:cell(selected)="row">
        </template>
        <template v-slot:cell(action)="row">
          <i
             class="row-icon-button row-action ml-2"
             :class="$config.icons.general.edit"
             @click="editSIM(row.item)"
             v-b-tooltip.hover
             title="Edit SIM"
          ></i>
          <i v-if="row.item.device_id === null"
            class="row-icon-button row-action ml-2"
            :class="$config.icons.general.assign"
            @click="assignSIM(row.item)"
            v-b-tooltip.hover
            title="Assign SIM to Device"
          ></i>
          <i v-if="row.item.device_id !== null"
             class="row-icon-button row-action ml-2"
             :class="$config.icons.general.unassign"
             @click="unAssignSIM(row.item)"
             v-b-tooltip.hover
             title="Unassign SIM"
          ></i>
          <i
            class="row-icon-button-danger row-action ml-2"
            :class="$config.icons.general.remove"
            @click="deleteSim(row.item)"
            v-b-tooltip.hover
            title="Delete SIM"
          ></i>
        </template>
      </b-table>
      </div>
      <div class="page-bar">
        <b-form-select
          v-model="perPage"
          id="perPageSelect"
          size="sm"
          :options="pageOptions"
          class="page-select"
        >
        </b-form-select>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="page-buttons"
        ></b-pagination>
      </div>
<!--    Edit SIM Card Modal-->
    <b-modal
      id="modal-edit-sim-card"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Edit SIM"
    >
      <admin-edit-sim-card :sim-card="selectedSIM" modal="modal-edit-sim-card" @save="refreshTable"></admin-edit-sim-card>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-edit-sim-card')">Close</b-button>
      </div>
    </b-modal>
<!--   Assign SIM to Device -->
    <b-modal
      id="modal-assign-sim-card"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Assign SIM to Device"
    >
      <admin-device-list :show-fields="['device_imei', 'name', 'device_type']" @row-selected="selectDevice"
                         select-mode="single"></admin-device-list>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-assign-sim-card')">Cancel</b-button>
        <b-button class="button" :disabled='!selectedDevice' @click="assignToDevice()">Assign Device</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import SearchFilters from '@/components/shared/SearchFilters'
import AdminEditSimCard from '@/components/admin/AdminEditSIMCard'
// import AdminDeviceList from '@/components/admin/AdminDeviceList'

export default {
  name: 'admin-sim-card-list',
  components: {
    AdminDeviceList: () => import ('./AdminDeviceList'),
    AdminEditSimCard,
    SearchFilters
  },
  props: {
    sim_cards: Array,
    selectable: Boolean,
    selectMode: String
  },
  data: function () {
    return {
      // Table Filtering
      filterMethod: 'remote', // Filter Locally or remotely at the server-side
      filter: '',
      showAdvancedFilters: false,
      hideFilter: false,
      loading: true,
      tableDataSource: null,
      tableFields: [
        {
          key: 'selected',
          label: '-'
        },
        { key: 'sim_id',
          label: 'sim_id',
          sortable: true
        },
        { key: 'status',
          label: 'Status',
          sortable: true,
          formatter: x => { return this.statusLookup[x] }
        },
        { key: 'device_imei',
          label: 'Device IMEI',
          sortable: true
        },
        { key: 'sim_provider_id',
          label: 'SIM Provider ID'
        },
        { key: 'sim_provider_config_id',
          label: 'Provider'
        },
        {
          key: 'action',
          label: 'Actions'
        }
      ],
      // Table Pagination Options
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      pageOptions: [
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' }
      ],
      filters: [
        {
          label: 'Filter By Status',
          type: 'select',
          placeholder: 'Status...',
          fieldName: 'status',
          options: [
            { value: '', text: 'None' },
            { value: '0', text: 'Issued' },
            { value: '1', text: 'Active' },
            { value: '2', text: 'Inactive' },
            { value: '3', text: 'Error' }
          ]
        },
        {
          label: 'Filter By SIM Id',
          type: 'string',
          placeholder: 'SIM Id...',
          fieldName: 'sim_id'
        },
        {
          label: 'Filter By ICCID',
          type: 'string',
          placeholder: 'ICCID...',
          fieldName: 'iccid'
        },
        {
          label: 'Filter By IMSI',
          type: 'string',
          placeholder: 'IMSI...',
          fieldName: 'imsi'
        },
        {
          label: 'Filter By MSISDN',
          type: 'string',
          placeholder: 'MSISDN...',
          fieldName: 'msisdn'
        }
      ],
      currentFilter: {},
      statusLookup: {
        0: 'Issued',
        1: 'Active',
        2: 'Inactive',
        3: 'Error'
      },
      selectedSIM: null,
      selectedDevice: null
    }
  },
  async mounted () {
    await this.refreshTable()
  },
  methods: {
    async refreshTable () {
      this.tableDataSource = this.adminGetSims
      this.$refs.dataTable.refresh()
    },
    async adminGetSims (ctx) {
      let filters = {}
      if (this.showAdvancedFilters) {
        filters = this.currentFilter
      }
      let resp = await DataProvider.adminGetSIMs(ctx.perPage, (ctx.currentPage - 1) * ctx.perPage, ctx.sortBy, ctx.sortDesc, filters)
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        this.totalRows = resp.data.total
        return resp.data.sim_cards
      }
    },
    applyFilter: function () {
      this.refreshTable()
    },
    changeFilter: function (filters) {
      console.log('Set Filters: ', filters)
      this.currentFilter = filters
    },
    resetFilters: function () {
      this.$refs.searchFilters.reset()
    },
    deleteSim: async function (sim) {
      console.log('Delete')
    },
    assignSIM: async function (sim) {
      this.selectedSIM = sim
      this.$bvModal.show('modal-assign-sim-card')
    },
    unAssignSIM: async function (sim) {
      console.log(sim)
      let confirm = await this.$bvModal.msgBoxConfirm('This SIM is associated with a device, ' +
        'are you sure you want to Unassign it?', {
        title: 'Confirm Unassignment',
        okVariant: 'warning',
        centered: true
      })
      if (confirm) {
        let resp = await DataProvider.adminUnassignSIM(sim.sim_id)
        if (!resp.success) {
          ErrorHelper.displayDataErrorToast(resp)
        } else {
          AlertHelper.successToast('Unassigned SIM Card', 'Changes Saved!')
          this.refreshTable()
        }
      }
    },
    editSIM: async function (sim) {
      this.selectedSIM = sim
      this.$bvModal.show('modal-edit-sim-card')
    },
    selectDevice: function (selection) {
      this.selectedDevice = selection[0]
    },
    assignToDevice: async function () {
      let res = await DataProvider.adminUpdateSIM(this.selectedSIM.sim_id, null, this.selectedDevice.device_imei)
      if (res.success) {
        AlertHelper.successToast('SIM Card Assigned to device!', 'Changes Saved!')
        this.refreshTable()
        this.$bvModal.hide('modal-assign-sim-card')
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    selectRow: function (selection) {
      this.$emit('row-selected', selection)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .notification-list {
    padding: 0.5rem;
    color: $text-color-invert;
  }

  .notification-table {
    background: $theme-color-background-1;
    max-height: 70vh;
    overflow-y: auto;
    padding: 10px;
  }

  .notification-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid rgba(121, 121, 121, 0.62);
  }

  .no-data-message {
    color: $theme-color-primary-3;
    font-style: italic;
  }

  .notification-timestamp {
    font-style: italic;
    margin-right: 1.2rem;
  }

  .clear-icon {
    color: red;
    opacity: 0.7;
    margin-left: 1rem;
  }

  .clear-icon:hover {
    opacity: 1;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 95%;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .type:first-letter {
    text-transform:capitalize;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

</style>
